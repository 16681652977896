import React from 'react'

import styles from './index.module.scss'

import { Container, Row, Col } from 'react-grid-system'

interface ContentWithSplitHeaderProps {
  topLeft?   : React.ReactNode;
  topRight?  : React.ReactNode;
  responsive?: boolean
}

export default class ContentWithSplitHeader extends React.Component<ContentWithSplitHeaderProps> {

  static defaultProps = {
    topLeft    : null,
    topRight   : null,
    responsive : true,
  }

  render() {
    const { responsive } = this.props
    return (
      <Container className={["Container", styles.Container].join(" ")}>
        <Row>
          <Col xs={responsive ? 12 : 6} sm={6} className={styles.TL}>
            {this.props.topLeft}
          </Col>
          <Col xs={responsive ? 12 : 6} sm={6} className={styles.TR}>
            {this.props.topRight}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {this.props.children}
          </Col>
        </Row>
      </Container>
    )
  }

}
