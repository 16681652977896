import React, { Fragment, useEffect, useState } from 'react'
import {Button, IconButton} from '@material-ui/core'
import {Menu} from '@material-ui/icons'
import withRouter from 'with/Router'

import { logout } from 'utils/auth';
import { AppBar, Toolbar, Typography } from '@material-ui/core'

import styles from './index.module.scss'
import { useHistory } from 'react-router';
import { useGlobal } from 'reactn';

const { CC_SHOW_TECHNICAL_PAGES } = process.env;

interface NavState {
  mobile: boolean,
  drawerOpen: boolean,
}

interface NavProps {
  //onMenuIconClick  : () => void
  [key: string]    : any
}

export function useNavMobileState() {
  const [mobile, setMobile] = useState<boolean>(window.innerWidth<=800)

  let lastWidth = window.innerWidth
  const resizeListener = () => {
    if (window.innerWidth <= 800 && lastWidth > 800) {
      setMobile(true)
    } else
    if (window.innerWidth > 800 && lastWidth <= 800) {
      setMobile(false)
    }
    lastWidth = window.innerWidth;
  }
  
  useEffect(() => {
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return mobile
}

export function Nav(props: NavProps) {
  const mobile = useNavMobileState()
  const history = useHistory()

  const logoutClicked = () => {
    logout();
    history.push('/login')
  }

  const navTo = (path: string) => {
    history.push(path)
  }

  const [metersDrawerOpen, setMetersDrawerOpen] = useGlobal<any, 'metersDrawerOpen'>('metersDrawerOpen')
  return (
    <AppBar >
      <Toolbar>
        <IconButton 
          edge       = "start"
          color      = "inherit"
          aria-label = "menu"
          onClick    = {() => setMetersDrawerOpen(!metersDrawerOpen)}
          >
          <Menu/>
        </IconButton>
        <Button
          className = {styles.NavButton}
          onClick   = {() => history.push('/meters')}
          >
          Meters
        </Button>
        {
          CC_SHOW_TECHNICAL_PAGES=="YES" &&
            <>
              <Button
                className = {styles.NavButton}
                onClick   = {() => history.push('/readings')}
                >
                Readings
              </Button>
              <Button
                className = {styles.NavButton}
                onClick   = {() => history.push('/raw-samples')}
                >
                Raw Samples
              </Button>
              <Button
                className = {styles.NavButton}
                onClick   = {() => history.push('/device-status')}
                >
                Device Status
              </Button>
            </>
        }
      </Toolbar>
    </AppBar>
  )
}
