import { gql } from '@apollo/client'

export const FRAG_READING = gql`
  fragment frag_Reading on Reading { 
    _id
    device {
      _id
      deviceId
      deviceName
    }
    timestamp
    m3_initial
    m3_current
  }
`

export const GET_READINGS_TABLE = gql`
  query GetReadingsTable {
    readingsTable {
      ...frag_Reading
    }
  }
  ${FRAG_READING}
`

export const SAVE_READINGS_TABLE = gql`
  mutation SaveReadingsTable(
    $readings: [ReadingInput!]!
  ) {
    saveReadingsTable(readings: $readings) {
      ...frag_Reading
    }
  }
  ${FRAG_READING}
`

