import React, { Fragment } from 'react'
import Paper from "@material-ui/core/Paper";

import styles from './index.module.scss'

//        m<sup>3</sup></td>

export function UsagePaper({ data, which }) {
  return (
    <Paper className={styles.UsagePaper}>
      <table>
        <tr>
          <td>Total water 01:00 to 02:00</td>
          <td>{(parseFloat(data.flowRate2am)).toFixed(3).toLocaleString()} </td>
        </tr>
        <tr>
          <td>24-hour usage</td>
          <td>{(parseFloat(data.usage24hours)).toFixed(1).toLocaleString()} </td>
        </tr>
        <tr>
          <td>7-day usage</td>
          <td>{(parseFloat(data.usage7days)).toFixed(1).toLocaleString()} </td>
        </tr>
        <tr>
          <td>30-day usage</td>
          <td>{(parseFloat(data.usage30days)).toFixed(1).toLocaleString()} </td>
        </tr>
      </table>
    </Paper>
  )
}