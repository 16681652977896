import { useMutation, useQuery } from '@apollo/client'
import { Button, Icon, IconButton, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { GET_READINGS_TABLE, SAVE_READINGS_TABLE } from 'api/Reading'
import { GetReadingsTable_readingsTable } from 'api/Reading/types/GetReadingsTable'
import { DateTimeField } from 'components/fields/DateTimeField'
import { TextField } from 'components/fields/TextField'
import { LoadingBox } from 'components/LoadingSpinner'
import moment from 'moment-timezone'
import * as omitDeep from 'omit-deep-lodash'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useState } from 'reactn'

interface Props {
  [key:string]: any
}
interface State {
  ivs: GetReadingsTable_readingsTable[]
}

const m3Format = Intl.NumberFormat("en-NZ", {maximumFractionDigits: 3})

export function ReadingsPage(props: Props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [readings, setReadings] = useState<GetReadingsTable_readingsTable[]>([])
  const [saveReadingsTable, {data: saveReadingsTableResult}] = useMutation(SAVE_READINGS_TABLE, {
    refetchQueries: () => ['GetReadingsTable'],
    awaitRefetchQueries: true,
    // update: (cache, mutationResult) => {
    //   const q = JSON.parse(JSON.stringify(cache.readQuery<GetReadingTable>({ query: GET_READINGS_TABLE })))
    //   q.readingsTable = mutationResult.data.saveReadingTable
    //   cache.writeQuery({ query: GET_READINGS_TABLE, data: q })
    //   setReadings(q.readingsTable)
    // }
  })

  const readingsQuery = useQuery(GET_READINGS_TABLE, {
    onCompleted: data => {
      setReadings((data.readingsTable || []).filter(e=>!!e.device))
      setLoading(false)
    }, 
    onError: error => setError(true)
  })

  const handleChangeReading = (x: GetReadingsTable_readingsTable, value: string) => {
    const idx = readings.findIndex(e => e.device.deviceId == x.device.deviceId)
    if (idx == -1) return;
    const rs = [ ...readings ]
    rs[idx] = {...x, m3_initial: parseFloat(value) }
    setReadings(rs)
  }

  const handleChangeTimestamp = (x: GetReadingsTable_readingsTable, value: string) => {
    const idx = readings.findIndex(e => e.device.deviceId == x.device.deviceId)
    if (idx == -1) return;
    const rs = [ ...readings ]
    rs[idx] = {...x, timestamp: value}
    setReadings(rs)
  }

  const handleClear = async (x: GetReadingsTable_readingsTable) => {
    const idx = readings.findIndex(e => e.device.deviceId == x.device.deviceId)
    if (idx == -1) return;
    const rs = [ ...readings ]
    rs[idx] = {
      ...x, 
      timestamp: null,
      m3_initial: null,
      m3_current: null,
    }
    setReadings(rs)
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      await saveReadingsTable({
        variables: {
          readings: omitDeep(readings.map(e=> ({
            ...e,
            deviceId: e.device.deviceId
          })), "__typename", "m3_current", "meter", "device")
        }
      })
    }
    catch (e) {
      console.error(e)
      setError(true)
    } 
    finally {
      setLoading(false)
    }
  }

  if (loading) return <LoadingBox padding={60} />
  if (error) return <Alert variant="filled" severity="error">Something went wrong</Alert>

  return (
    <Container style={{marginTop: '4rem'}}>
      <Row>
        <Col xs={12} sm={1.5}>
          <Typography>Device</Typography>
        </Col>
        <Col xs={10} sm={3}>
          <Typography>Date/Time</Typography>
        </Col>
        <Col xs={2} sm={2}>
        </Col>
        <Col xs={12} sm={2}>
          <Typography>Initial Value</Typography>
        </Col>
        <Col xs={12} sm={2.5}>
          <Typography>Current Value</Typography>
        </Col>
        <Col xs={12} sm={1}>
          <Typography>Clear</Typography>
        </Col>
      </Row>
      {readings.map((e, i) => 
        <Row key={i}>
          <Col xs={12} sm={1.5} style={{lineHeight: 4}}>
            {e.device.deviceName}
          </Col>
          <Col xs={10} sm={3}>
            <DateTimeField
              noPadding
              input={{
                value: e.timestamp ? moment(e.timestamp).toDate() : null,
                onChange: value => handleChangeTimestamp(e, value)
              }}
              />
          </Col>
          <Col xs={2} sm={2}>
            <Button
              onClick={() => handleChangeTimestamp(e, new Date().toISOString())}>
              NOW
            </Button>
          </Col>
          <Col xs={12} sm={2}>
            <TextField
              inputProps = {{type: 'number'}}
              metric     = "m3"
              input      = {{
                value: (e.m3_initial || '') as string,
                onChange: value => handleChangeReading(e, value)
              }}
              />
          </Col>
          <Col xs={12} sm={2.5} style={{lineHeight: 3.5, textAlign:'right'}}>
            {m3Format.format(e.m3_current)} m<sup>3</sup>
          </Col>
          <Col xs={12} sm={1} style={{lineHeight: 4}}>
            <IconButton
              onClick={() => handleClear(e)}>
              <Icon style={{fontWeight: 'bold'}}>clear</Icon>
            </IconButton>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} style={{display: 'flex', justifyContent: 'center', paddingTop: '4rem'}}>
          <Button
            variant={'contained'}
            onClick={handleSave}
            >
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
