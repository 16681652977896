import { useQuery } from '@apollo/client'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { GET_RAW_SAMPLES } from 'api/Sample'
import { LoadingBox } from 'components/LoadingSpinner'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useState } from 'reactn'
import moment from 'moment-timezone'

interface Props {
  [key:string]: any
}

const m3Format = Intl.NumberFormat("en-NZ", {maximumFractionDigits: 3})

export function RawSamplesPage(props: Props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [samples, setRawSamples] = useState<any[]>([])

  const samplesQuery = useQuery(GET_RAW_SAMPLES, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setRawSamples(data.rawSamples || [])
      setLoading(false)
    }, 
    onError: error => setError(true)
  })

  if (loading) return <LoadingBox padding={60} />
  if (error) return <Alert variant="filled" severity="error">Something went wrong</Alert>

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Typography>RAW JSON</Typography>
        </Col>
      </Row>
      {samples.map((e, i) => 
        <Row key={i} style={i%2?{}: {backgroundColor: '#ffe0de'}}>
          <Col xs={2} style={{verticalAlign: 'top'}}>
            {moment(e.timestamp).format("DD/MM/YYYY H:mm")}
          </Col>
          <Col xs={9}>
            <b>
            Counter: {e.value} <br/>
            DeviceID: {e.deviceId} <br/>
            </b>
            <pre>
              {JSON.stringify(JSON.parse(e.webhookMessage), null, 4)}
            </pre>
          </Col>
        </Row>
      )
      }
    </Container>
  )
}
