import React from 'react'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

export default class Paper extends React.Component<any> {
  
  render() {
    const { className, ...otherProps } = this.props;
    return (
      <div className={[styles.Paper, className || ''].join(' ')} {...otherProps}>
        {this.props.children}
      </div>
    )
  }

}
