import React from 'react'

import styles from './index.module.scss'

export default class Center extends React.Component {
  
  render() {
    return (
      <div className={styles.Center} {...this.props}>
        {this.props.children}
      </div>
    )
  }

}
