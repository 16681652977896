import { gql } from '@apollo/client'

export const FRAG_RAW_SAMPLE = gql`
  fragment frag_RawSample on Sample {
    _id
    webhookMessage
    value
    delta
    deviceId
    timestamp
  }
`

export const GET_RAW_SAMPLES = gql`
  query GetRawSamples {
    rawSamples {
      ...frag_RawSample
    }
  }
  ${FRAG_RAW_SAMPLE}
`

export const GET_CHART_SAMPLES_PER_METER = gql`
  query GetChartSamplesPerMeter(
    $deviceIds: [String!],
    $t0: Time!,
    $t1: Time!,
  ) {
    chartSamplesPerMeter(
      deviceIds: $deviceIds,
      t0: $t0,
      t1: $t1,
    ) {
      _id
      device {
        _id
        deviceId
        deviceName
      }
      samples {
        _id
        timestamp
        value
        delta
      }
      flowRate2am
      usage24hours
      usage7days
      usage30days
    }
  }
`
