import { Query } from '@apollo/client/react/components/Query'
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core'
import { GET_DEVICES } from 'api/DeviceStatus'
import { GetDevices } from 'api/DeviceStatus/types/GetDevices'
import { useNavMobileState } from 'components/Nav'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useGlobal } from 'reactn'
import styles from './index.module.scss'


const DRAWER_SELECTED_COLOR = '#ffcdd2';


export function MetersDrawer(props: any) {
  const history = useHistory()
  const match   = useRouteMatch<{id: string}>()
  const mobile  = useNavMobileState()

  const [drawerVariant, setDrawerVariant] = useState("temporary")
  const [metersDrawerOpen, setMetersDrawerOpen] = useGlobal<any, 'metersDrawerOpen'>('metersDrawerOpen')

  const updateDrawerState = () => {
    if (mobile) {
      setDrawerVariant("temporary")
      setMetersDrawerOpen(false)
    } else {
      setDrawerVariant("permanent")
      setMetersDrawerOpen(true)
    }
  }
  useEffect(updateDrawerState, [])
  useEffect(updateDrawerState, [mobile])
  return (
    <Query<GetDevices>
      query       = {GET_DEVICES}
      variables   = {{}}
      fetchPolicy = "network-only"
      >
      {({ networkStatus, data }) => (
        <Drawer
          className = {styles.MetersDrawer}
          variant   = {drawerVariant as any}
          open      = {metersDrawerOpen}
          >
          <List>
            <ListItem button onClick={() => { 
              if (drawerVariant == "temporary") {
                setMetersDrawerOpen(false)
              }
              history.push('/meter/all');
            }}
              style = {{
                background: match.params.id === 'all' ? DRAWER_SELECTED_COLOR : 'white'
              }}
              >
              <ListItemText primary={'All Meters'} />
            </ListItem>
          </List>
          <List>
            {(data?.devices||[]).map((dev, index) => (
              <ListItem button key={dev.deviceId}  onClick={() => {
                if (drawerVariant == "temporary") {
                  setMetersDrawerOpen(false)
                }
                history.push('/meter/'+dev.deviceId)
              }}
                style = {{
                  background: match.params.id === dev.deviceId.toString() ? DRAWER_SELECTED_COLOR : 'white'
                }}
                >
                <ListItemText primary={dev.deviceName} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      )}
      </Query>
  )
}