import { CssBaseline } from '@material-ui/core'
import Button from 'components/buttons/Button'
import LoadingScreen from 'components/LoadingScreen'
import Modal from 'components/Modal'
import { Nav } from 'components/Nav'
import Page from 'layout/Page'
import LoginPage from 'pages/Login'
import { MetersPage } from 'pages/Meters'
import { ReadingsPage } from 'pages/Readings'
import React, { Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import withRouter from 'with/Router'
import withStore from 'with/Store'
import styles from './App.module.scss'
import AppContext, { actions, AppContextState, initialState, withContext } from './AppContext'
import {setGlobal} from 'reactn'
import { RawSamplesPage } from 'pages/RawSamples'
import { DeviceStatusPage } from 'pages/DeviceStatus'


setGlobal({
  metersDrawerOpen: !(window.innerWidth<=800),
})

interface AppProps {
  error?: string
}

@withRouter
@withStore((state: any) => ({}))
@(withContext as any)
class App extends React.Component<any, any> {

  constructor(props:any) {
    super(props);
   // (props as any).context.setLoading(true);
  }

  renderLoggedIn() {
    return (
      <Fragment>
        <Page>
          <Nav />
          <Switch>
            <Route path='/meter/:id' exact component={MetersPage} />
            <Route path='/readings' exact component={ReadingsPage} />
            <Route path='/device-status' exact component={DeviceStatusPage} />
            <Route path='/raw-samples' exact component={RawSamplesPage} />
            <Redirect to='/meter/all' />
          </Switch>
        </Page>
      </Fragment>
    )
  }

  renderLoggedOut() {
    return (
      <Fragment>
        <Switch>
          <Route path='/login' component={LoginPage} />

          <Redirect to='/login' />
        </Switch>
      </Fragment>
    )
  }

  componentDidMount() {
  /*  setTimeout(() => {
      (this.props as any).context.setLoading(false);
    }, 1500);*/
  }

  render() {
    const content = //isLoggedIn() ? this.renderLoggedIn() : this.renderLoggedOut();
        this.renderLoggedIn()

    const { error } = this.props.context

    return (
      <div className={styles.App}>
        <CssBaseline />
        
        <Modal open={error && error.show} zIndex={20}>
          <h1>{error && error.title || 'Oops..'}</h1>
          <p style={{fontSize: '1.6rem'}}>
            {error && error.message || 'Something went wrong'}
          </p>
          <br/>
          <Button 
            color     = 'primary'
            onClick   = {() => {
              this.props.context.hideErrorModal()
            }}>
            OK 
          </Button>
        </Modal>

        <LoadingScreen
          visible={this.props.context.loading}
          />

        {content}
      </div>
    );
  }
}


export default class AppWithContext extends React.Component<any, AppContextState> {

  constructor(props:any) {
    super(props)

    this.state = {
      ...initialState,
      ...(actions(this))
    }
  }

  render() {
    console.log("AppWithContext", this)
    return (
      <AppContext.Provider value={this.state}>
        <App {...this.props} />
      </AppContext.Provider>
    )
  }

}


