import { Query } from '@apollo/client/react/components';
import { GET_CHART_SAMPLES_PER_METER } from 'api/Sample';
import { GetChartSamplesPerMeter, GetChartSamplesPerMeterVariables } from 'api/Sample/types/GetChartSamplesPerMeter';
import { withContext } from 'AppContext';
import LoadingSpinner from 'components/LoadingSpinner';
import moment from 'moment-timezone';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ChartTableSingle } from '../components/ChartTable';
import { make10minIntervalArray } from '../utils';


@(withRouter as any)
@(withContext as any)
export default class MeterPage extends React.Component<any, any> {

  private timeStart: Date;
  private timeStamps: Date[];

  constructor(props: any) {
    super(props)
    this.timeStart = moment().subtract(1, 'day').toDate();
    this.timeStamps = make10minIntervalArray(this.timeStart);
    //DEBUG
//    this.timeStamps = make10minIntervalArray(moment('2020-01-12 17:25').toDate(), moment('2020-01-13 17:25').toDate());
  }

  render() {
    return (
      <div style={{paddingLeft: '0rem', overflowX: 'hidden', height: 'inherit'}}>
        <Query<GetChartSamplesPerMeter, GetChartSamplesPerMeterVariables>
          query                       = {GET_CHART_SAMPLES_PER_METER}
          notifyOnNetworkStatusChange = {true}
          fetchPolicy                 = {'network-only'}
          variables                   = {{
            deviceIds: [this.props.match.params.id],
            t0       : moment(this.timeStart).toISOString(), //.format("YYYY-MM-DD HH:mm:ss.000Z"),
            t1       : moment().toISOString(), //.format("YYYY-MM-DD HH:mm:ss.000Z"),
          }}
          >
          {({networkStatus, data}) => {
            if (networkStatus <= 6) {
              //return <LoadingScreen visible={true} text={"Loading charts"}/>
              return <LoadingSpinner color={"var(--loadingSpinnerColor)"}/>
            }    
            return (
              <div style={{width: '100%', height: 'calc(100% - 5rem)'}}>
                {(networkStatus > 6) && data &&
                  <ChartTableSingle
                    which      = {this.props.match.params.id}
                    timestamps = {this.timeStamps}
                    dataset    = {data.chartSamplesPerMeter[0]}
                    />
                }
              </div>
            )
        }}
        </Query>
      </div>
    )
  }

}
