import React from 'react'
import styles from './index.module.scss'
import moment from 'moment-timezone'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';

import omitDeep from 'omit-deep-lodash'
import { InputAdornment, Icon } from '@material-ui/core'

interface DateTimeFieldProps {
  input: {
    value : Date | string,
    onChange: (value: string) => any,
  };
  noPadding?: boolean
  [key: string] : any
}

export class DateTimeField extends React.Component<DateTimeFieldProps> {

  static defaultProps = {
  }

  constructor(props: DateTimeFieldProps) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    const isodate = moment(value).toISOString()
    this.props.input.onChange(isodate);
  }

  render() {
    const { meta = {}, input } = this.props;
    const { submitFailed, error } = meta;
    const hasError = Boolean(submitFailed && error)
    
    const value = input.value ? moment(input.value).format("YYYY-MM-DD HH:mm") : null;
    const cls = [styles.DateTimeField]
    if (this.props.noPadding) {
      cls.push(styles.noPadding)
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDateTimePicker
            className      = {cls.join(' ')}
            disableToolbar = {true}
            disablePast    = {false}
            disableFuture  = {true}
            ampm           = {false}
            variant        = "inline"
            inputVariant   = "filled"
            format         = "DD/MM/YYYY HH:mm"
            placeholder    = "DD/MM/YYYY HH:mm"
            margin         = "dense"
            value          = {value}
            fullWidth      = {true}
            onChange       = {this.handleChange}
            error          = {hasError}
            helperText     = {hasError && error}
            autoOk         = {true}
            {...omitDeep(this.props, "input")}
            InputProps     = {{
              endAdornment: <InputAdornment position="end"><Icon style={{color: 'currentColor'}}>access_time</Icon></InputAdornment>,
              ...(this.props.InputProps || {})
            }}
          />
      </MuiPickersUtilsProvider>
    )
  }

}
