import React from 'react'
import PropTypes from 'prop-types'

import LoadingSpinner from 'components/LoadingSpinner'

import styles from './index.module.scss'

interface ButtonProps {
  fullWidth?: boolean
  onClick?  : (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  color?    : "primary" | "secondary" | "obligationGrey"
  loading?  : boolean
  style?    : React.CSSProperties
  className?: string
  type?     : "button" | "submit"
  square    : boolean
  disabled  : boolean
}

export default class Button extends React.Component<ButtonProps> {
 
  static defaultProps = {
    fullWidth: true,
    color    : 'primary',
    loading  : false,
    style    : {},
    type     : 'button',
    square   : false,
    disabled : false,
  }

  constructor(props: ButtonProps) {
    super(props)
  }

  render() {
    let classes = [styles.Button]
    if (this.props.fullWidth) {
      classes.push(styles.fullWidth)
    }
    classes.push(styles[this.props.color as string])

    if (this.props.square) {
      classes.push(styles.square)
    }

    if (this.props.className) classes.push(this.props.className)

    const loadingIcon = <LoadingSpinner size={16} />
    return (
      <button 
        type      = {this.props.type}
        className = {classes.join(' ')}
        onClick   = {this.props.onClick}
        style     = {this.props.style}
        disabled  = {this.props.disabled}
        >
        {this.props.loading ? loadingIcon : this.props.children}
      </button>
    )
  }

}
