import { NetworkStatus } from '@apollo/client'
import { Query } from '@apollo/client/react/components/Query'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { GET_DEVICES } from 'api/DeviceStatus'
import { LoadingBox } from 'components/LoadingSpinner'
import Paper from 'components/Paper'
import moment from 'moment-timezone'
import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

interface Props {
  [key:string]: any
}
interface State {
}

export function DeviceStatusPage(props: Props) {
  return (
    <Query 
      query={GET_DEVICES}
      fetchPolicy="network-only"
      >
      {({data, networkStatus}) => (
        <>
         {networkStatus==NetworkStatus.error && 
            <Alert variant="filled" severity="error">Something went wrong</Alert>
         }
         {networkStatus<NetworkStatus.ready &&
            <LoadingBox padding={60} />
         }
         {networkStatus==NetworkStatus.ready &&
            <Container style={{marginTop: '4rem'}} className="Paper">
              <Row>
                <Col xs={12} sm={2.5}>
                  <Typography>Name</Typography>
                </Col>
                <Col xs={12} sm={2.5}>
                  <Typography>DeviceId</Typography>
                </Col>
                <Col xs={12} sm={3}>
                  <Typography>Gateway(s)</Typography>
                </Col>
                <Col xs={12} sm={4}>
                  <Typography>Last Webhook Received At</Typography>
                </Col>
              </Row>
              {data?.devices && data.devices.map((e, i) => 
                <Row key={i} style={{borderTop: '0.1rem solid #000'}}>
                  <Col xs={12} sm={2.5}>
                    {e.deviceName}
                  </Col>
                  <Col xs={12} sm={2.5}>
                    {e.deviceId}
                    {e.battery > -1 &&
                      <>
                        <br/>Battery: {e.battery}%
                      </>
                    }
                  </Col>
                  <Col xs={12} sm={3}>
                    {e.gateways?.map((gw) => (
                      <div key={gw._id}>
                        {gw.gatewayName}<br/>
                        <blockquote style={{marginTop: '0.5rem'}}>
                          RSSI: {gw.rssi}<br/>
                          SNR: {gw.snr}
                        </blockquote>
                      </div>
                    ))}
                  </Col>
                  <Col xs={12} sm={4}
                    style={moment().diff(e.lastWebhookReceivedAt, "minutes") > 60 ? {
                      backgroundColor: "#FF0000",
                    } : {
                      backgroundColor: "#00FF00",
                    }}
                    >
                    {moment(e.lastWebhookReceivedAt).format("DD/MM/YYYY HH:mm:ss")}<br/>
                    {moment(e.lastWebhookReceivedAt).fromNow(false)}<br/>
                  </Col>
                </Row>
              )}
            </Container>
         }
        </>
      )}
    </Query>
  )
}
