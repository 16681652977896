import { GetChartSamplesPerMeter_chartSamplesPerMeter, GetChartSamplesPerMeter_chartSamplesPerMeter_samples } from 'api/Sample/types/GetChartSamplesPerMeter';
import CCLineChart from 'components/Chart';
import moment from 'moment';
import { METER_COLORS, METER_MULTIPLIERS } from 'pages/Meters/utils';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { UsagePaper } from '../UsagePaper';
import * as omitDeep from 'omit-deep-lodash'

export interface ChartTableAllProps {
  timestamps: Date[]
  dataset: GetChartSamplesPerMeter_chartSamplesPerMeter[]
}

export const LPERMIN_TO_M3PERH = 0.06

export function ChartTableAll(props: ChartTableAllProps) {

  const { timestamps, dataset } = props

  const ds = dataset.map((e,idx) => ({
    ...omitDeep(e, "samples"),
    data: (e.samples || []).map(point => ({
      x: moment(point.timestamp).toDate(),
      y: point.delta * LPERMIN_TO_M3PERH
    })),
    color: '#367f2e', //METER_COLORS[key]
  }));

  return (
    <Container className="Container">
      <Row>
        {
          ds.map((e,i) => {
            return (
              <Col xs={12} key={i} style={{display:'flex', flexFlow:'column', alignItems:'center', paddingBottom:'5rem'}}>
                <h2>{e.device?.deviceName}</h2>
                <CCLineChart
                  _times = {timestamps}
                  _dataSets  = {[{
                    color      : e.color,
                    label      : (i+1).toString(),
                    chartPoints: e.data
                  }]}
                  last24HourPulsesInReverse={[]}
                  autoScale={true}
                  />
                  <UsagePaper data={e} which={i+1}/>
              </Col>
            )
          }) 
        }
      </Row>
    </Container>
  )
}


interface ChartTableSingleProps {
  which: string
  timestamps: Date[]
  dataset: GetChartSamplesPerMeter_chartSamplesPerMeter
}

export function ChartTableSingle(props: ChartTableSingleProps) {

  const { which, timestamps, dataset } = props

  const ds = {
    ...omitDeep(dataset, "samples"),
    data: (dataset.samples || []).map(point => ({
      x: moment(point.timestamp).toDate(),
      y: point.delta * LPERMIN_TO_M3PERH
    })),
    color: '#367f2e',
  }

  return (
    <Container className="Container">
      <Row>
        <Col xs={12} style={{display:'flex', flexFlow:'column', alignItems:'center'}}>
          <CCLineChart
            _times = {timestamps}
            _dataSets  = {[{
              color      : ds.color,
              label      : 'Meter '+(which).toString(),
              chartPoints: ds.data
            }]}
            last24HourPulsesInReverse={[]}
            autoScale={true}
            />
          <UsagePaper data={ds} which={which}/>
        </Col>
      </Row>
    </Container>
  )
}
