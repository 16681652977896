import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#cc3636',
      main : '#cc3636',
      dark : '#cc3636',
    }
  },
  typography: {
    body1: {
      fontSize: '1.6rem'
    },
    allVariants: { 
      fontSize: '1.6rem'
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '1.7rem'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.6rem'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1.2rem'
      }
    }
  }
})

theme = responsiveFontSizes(theme, { 
});

export { theme };