import React from 'react'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import moment from 'moment-timezone'

import {Chart, ChartPoint} from 'chart.js'
import uuid from 'uuid'
import styles from './index.module.scss'
import { getMaxListeners } from 'cluster'

interface ChartSeriesData {
  color: string
  chartPoints: Chart.ChartPoint[]
  label: string
}

interface CCLineChartProps {
  last24HourPulsesInReverse: number[];
  _times                   : Date[]
  _dataSets                : ChartSeriesData[]
  autoScale                : boolean
}


@(withRouter as any)
export default class CCLineChart extends React.Component<CCLineChartProps> {

  private chartCanvasId: string = `chart${new Date().getTime()}${Math.floor(Math.random()*10000)}`;
  private chart: Chart = null;

  private id = uuid();
  private screenListener: any;

  constructor(props: CCLineChartProps) {
    super(props)
  }

  getLast24Hours(): moment.Moment[] {
    return [...new Array(24)].map((e, i) => moment().subtract(i, 'hours'))
  }

  getChartConfig() : Chart.ChartConfiguration {
    const getMaxY = () => {
      let y : any = -Infinity;
      for (let ds of this.props._dataSets) {
        for (let p of ds.chartPoints) {
          if (y < p.y) y = p.y;
        }
      }
      console.log("max y", y);
      return y;
    }
    return {
      type: 'line',
      data: {
        //labels: this.getLast24Hours().map(e => e.format('h a')),
      //  labels: this.props._times.map(e => moment(e).format('YYYY MM DD HH:mm')),

        datasets: this.props._dataSets.map((series,i): Chart.ChartDataSets => ({
        //  label          : series.label,
          backgroundColor: series.color,
          borderColor    : series.color,
          data           : series.chartPoints,   //makeChartPointArray(series.values, series.times),
          fill           : false,
          pointRadius    : 1,
        }))
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: '',
        },
        scales: {
					xAxes: [{
            type: 'time',
						time: {
							//parser: 'MM/DD/YYYY HH:mm',
              // round: 'day'
              parser       : sss => moment(sss),
              tooltipFormat: 'HH:mm',
              unit         : 'minute',
              unitStepSize : 60,
              min          : moment(this.props._times[0]).format('YYYY MM DD HH:mm'),
              max          : moment(this.props._times[this.props._times.length-1]).format('YYYY MM DD HH:mm'),
              displayFormats: {
                minute: 'HH:mm'
              }
						},
						scaleLabel: {
						}
					}],
					yAxes: [{
            display: true,
						scaleLabel: {
							display: true,
							labelString: 'm³ / h'
            },
            ticks: {
              min: 0,
              max: getMaxY()
            }
					}]
				}
      }
    };
  }

  componentDidMount() {
    const cfg = this.getChartConfig()
    const ctx : HTMLCanvasElement = document.getElementById(this.chartCanvasId) as HTMLCanvasElement;
    this.chart = new Chart(ctx, cfg)
    
    this.screenListener = () => {
      const isMobile = window.innerWidth < 800;

      const container = document.getElementById(this.id);
      container.style.height = (window.innerHeight - 50 - 250) + 'px';

      container.style.marginLeft = isMobile ? '-3rem' : '0';
      container.style.width = isMobile ? 'calc( 100% + 1rem )' : '100%';
      
      
      this.chart.config.options.scales.xAxes[0].time.unitStepSize = isMobile ? 120 : 60;
      if (isMobile) {
        delete this.chart.config.options.scales.yAxes[0].scaleLabel.labelString;
      } else {
        this.chart.config.options.scales.yAxes[0].scaleLabel.labelString = "m³ / h";
      }
    }
    this.screenListener();
    window.addEventListener('resize', this.screenListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.screenListener);
  }


  render() {
    return (
      <div id={this.id} style={{position: "relative", width: '90%'}}>
        <canvas id={this.chartCanvasId}>
        </canvas>
      </div>
    )
  }

}
