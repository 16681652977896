import { 
  createStore, 
  combineReducers
} from 'redux'

import { reducer as formReducer } from 'redux-form'

const reduxDevTools = process.env.NODE_ENV === 'development' ? (
  window.__REDUX_DEVTOOLS_EXTENSION__ && 
  window.__REDUX_DEVTOOLS_EXTENSION__()
) : undefined

const store = createStore( 
  combineReducers({
    form: formReducer,
  }), 
  /*applyMiddleware(thunk)*/
  reduxDevTools
)

export default store 
