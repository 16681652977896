import {ApolloClient, InMemoryCache} from '@apollo/client'

const {
  CC_API_URL
} = process.env;

const cache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  cache,
  uri: CC_API_URL,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
});
