import React from 'react'

import styles from './index.module.scss'

interface LoadingSpinnerProps {
  size?: number
  color?: string
}

export default class LoadingSpinner extends React.Component<LoadingSpinnerProps> {

  static defaultProps = {
    size: 64,
    color: '#FFF'
  }

  constructor(props: LoadingSpinnerProps) {
    super(props)
  }

  render() {
    const { color, size } = this.props
    return (
      <div className={styles.LoadingContainer}>
        <svg className={styles.SpinnerSvg} version="1.1"  x="0px" y="0px" viewBox="0 0 140 140" width={`${size}px`} height={`${size}px`}>
          <circle cx="70" cy="70" fill="none" stroke={this.props.color} strokeWidth="5" r="60" strokeDasharray="282.74333882308133 96.24777960769379" transform="rotate(57.6677 70 70)" />
        </svg>
      </div>
    )
  }

}


interface LoadingBoxProps {
  size? : number;
  padding? : number;
}

export class LoadingBox extends React.Component<LoadingBoxProps> {

  static defaultProps = {
    padding: 50,
    size: 64,
  }

  constructor(props: LoadingBoxProps) {
    super(props)
  }

  render() {
    return (
      <div className={styles.LoadingBox} style={{padding: `${this.props.padding}px`}}>
        <LoadingSpinner size={this.props.size} color={'#495057'} />
      </div>
    )
  }

}

export const LoadingBoxList = () => <LoadingBox size={32} padding={20} />
