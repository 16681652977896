import React, { KeyboardEventHandler } from 'react'
import MUITextField from '@material-ui/core/TextField'
import styles from './index.module.scss'
import omitDeep from 'omit-deep-lodash'
import { CircularProgress, InputAdornment } from '@material-ui/core';

export interface TextFieldProps {
  metric?  : "dollar" | "meter" | "liter" | "hashtag" | "m2" | "m3" | "%" | "phone" | string;
  input?   : {
    value : string,
    onChange: (value: string, event: React.SyntheticEvent<any, Event>) => any,
  }
  // field?   : {
  //   name    : string
  //   value   : string
  //   meta    : any
  //   onChange: (event: React.SyntheticEvent<any, Event>) => any,
  // }
  onKeyPress?: KeyboardEventHandler;
  onFocus?: any
  errorText?: string
  inputRef?: React.Ref<any>
  greyedOut?: boolean
  customEndAndornment?:any
  shrinkLabel?: boolean
  inputProps?: any
  loading?: boolean
  [key: string] : any
}

export class TextField extends React.Component<TextFieldProps> {

  static defaultProps = {
    onKeyPress: null,
    shrinkLabel: true,
    loading: false,
  }

  constructor(props: TextFieldProps) {
    super(props)
  }

  render() {
    const { meta = {}, errorText } = this.props;
    const { submitFailed, error } = meta;
    const hasError = Boolean((submitFailed && error) || errorText)
    const errorString = hasError ? (submitFailed && error) || errorText : null;

    let InputProps : any = {};
    if (this.props.metric) {
      switch (this.props.metric) {
        case "dollar": InputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> }
          break;
        case "meter": InputProps = { endAdornment: <InputAdornment position="end">m</InputAdornment> }
          break;
        case "m2": InputProps = { endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment> }
          break;
        case "m3": InputProps = { endAdornment: <InputAdornment position="end">m<sup>3</sup></InputAdornment> }
          break;
        case "liter": InputProps = { endAdornment: <InputAdornment position="end">L</InputAdornment> }
          break;
        case "hashtag": InputProps = { startAdornment: <InputAdornment position="start">#</InputAdornment> }
          break;
        case "%": InputProps = { endAdornment: <InputAdornment position="end">%</InputAdornment> }
          break;
        case "phone": InputProps = { startAdornment: <InputAdornment position="start">+64</InputAdornment> }
          break;
        default: 
          InputProps = { endAdornment: <InputAdornment position="end">{this.props.metric}</InputAdornment> }
      }
    }

    // if (this.props.customEndAndornment) {
    //   InputProps.endAdornment = this.props.customEndAndornment
    // }

    if (this.props.inputProps) {
      Object.assign(InputProps, this.props.inputProps)
    }

    if (this.props.loading) {
      InputProps.endAdornment = (
        <CircularProgress color="inherit" size={20} />
      )
    }

    const classNames = [styles.TextField, styles.FixTextAreaHeight];
    if (!this.props.label) {
     classNames.push(styles.WithoutLabel);
    }
    if (this.props.greyedOut) {
      classNames.push(styles.GreyedOut)
    }
    if (this.props.className) {
      classNames.push(this.props.className)
    }
    // const field = this.props.field || this.props.input;
    const field = this.props.input;
    return (
      <MUITextField
        name            = {this.props.name}
        ref             = {this.props.inputRef}
        className       = {classNames.join(' ')}
        value           = {field.value}
        onFocus         = {this.props.onFocus}
        onKeyPress      = {this.props.onKeyPress}
        onChange        = {event => {
          let val : any = event.target.value;
          if (this.props.type=="number") {
            try {
              val = parseFloat(val)
              if (isNaN(val)) val=null;
            }
            catch (e) {
              val = null
            }
          }
          if (this.props.input) this.props.input.onChange(val, event)
          if (this.props.field) this.props.field.onChange(event)
          if (this.props.onChangeDirect) this.props.onChangeDirect(val)
        }}
        fullWidth       = {true}
        margin          = "dense"
        error           = {hasError}
        helperText      = {errorString}
        variant         = "filled"
        InputLabelProps = {{shrink:this.props.shrinkLabel}}
        InputProps      = {InputProps}
        style           = {{paddingTop: '0rem !important'}}
        placeholder     = {this.props.placeholder || this.props.hintText}
        disabled        = {this.props.disabled || this.props.loading}
        {...omitDeep(this.props, 
            'className',
            'input',
            'field',
            'errorText',
            'hintText',
            'onChange',
            'onChangeDirect',
            'greyedOut',
            'shrinkLabel',
            'loading',
            'disabled',
            //'customEndAndornment'
            )
        }
      />
    )
  }

}
