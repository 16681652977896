import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'

import Paper from 'components/Paper'

import styles from './index.module.scss'

const ANIMATION_DURATION = 250;
const Z_INDEX_BASE = 101

const Status = {
  HIDDEN      : 'hidden',
  FADING_IN   : 'fadingIn',
  VISIBLE     : 'visible',
  FADING_OUT  : 'fadingOut'
}

export interface ModalProps {
  open      : boolean
  zIndex?   : number
  style?    : Object
  className?: string
}

interface ModalState {
  status: string
}

@(withRouter as any)
export default class Modal extends React.Component<ModalProps, ModalState> {
 
  static defaultProps = {
    open: false
  }

  constructor(props: ModalProps) {
    super(props)
    this.state = {
      status : props.open ? Status.VISIBLE : Status.HIDDEN
    }
  }

  componentWillReceiveProps(nextProps: ModalProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ status: Status.FADING_IN })
      setTimeout(() => this.setState({ status: Status.VISIBLE }), ANIMATION_DURATION)
    } 
    if (this.props.open && !nextProps.open) {
      this.setState({ status: Status.FADING_OUT })
      setTimeout(() => this.setState({ status: Status.HIDDEN }), ANIMATION_DURATION)
    }
  }

  render() {
    if (this.state.status === Status.HIDDEN) return null

    const zz : any = {}
    if (this.props.zIndex) {
      zz.style = {
        zIndex: Z_INDEX_BASE + this.props.zIndex
      }
    }

    let fx:string[]    = []
    let bgfx: string[] = []

    switch (this.state.status) {
      case Status.HIDDEN: 
        bgfx = [styles.bgHidden]
        fx = [styles[Status.HIDDEN]]
        break
      case Status.FADING_IN: 
        bgfx = [styles.bgFadingIn]
        fx = [styles[Status.FADING_IN]]
        break
      case Status.FADING_OUT: 
        bgfx = [styles.bgFadingOut]
        fx = [styles[Status.FADING_OUT]]
        break
      case Status.VISIBLE: 
        bgfx = [styles.bgVisible]
        fx = [styles[Status.VISIBLE]]
        break
    }
    const styleprops:any={}
    if (this.props.style) styleprops.style = this.props.style
    if (this.props.className) styleprops.className = this.props.className
    return (
      <div className={[styles.ModalOverlay, ...bgfx].join(' ')} {...zz}>
        <div className={[styles.ModalContainer, ...fx].join(' ')} >
          <Paper  {...styleprops}>
            {this.state.status !== Status.HIDDEN && this.props.children}
          </Paper>
        </div>
      </div>
    )
  }

}
