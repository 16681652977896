import React from 'react'
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom'

import styles from './index.module.scss'

import Icon from '@material-ui/core/Icon'
import ContentWithSplitHeader from 'layout/ContentWithSplitHeader';
import List from 'lists/List'
import Button from 'components/buttons/Button'
import moment from 'moment-timezone'
import { TextField } from 'components/fields/TextField';
import { withContext } from 'AppContext'
import { setToken } from 'utils/auth';
import Center from 'layout/Center';


@(withRouter as any)
@(withContext as any)
export default class LoginPage extends React.Component<any, any> {

  constructor(props: RouteComponentProps) {
    super(props)
    this.state = {
      password: ''
    }
  }

  render() {
    return (
      <Center>
        <ContentWithSplitHeader
          topLeft  = {<h1>Login</h1>}
          >
          <TextField
            label="Password"
            type="password"
            input={{
              value: this.state.password,
              onChange: password => this.setState({ password })
            }}
            />
          <br/>
          <br/>
          <Button 
            color     = "primary"
            square    = {true}
            fullWidth = {false}
            onClick   = {async () => {
            
            }}
            >
            Login
          </Button>

        </ContentWithSplitHeader>
      </Center>
    )
  }

}
