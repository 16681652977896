import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import * as serviceWorker from './serviceWorker';

import { ThemeProvider } from '@material-ui/styles'
import { ScreenClassProvider } from 'react-grid-system'
import { setConfiguration } from 'react-grid-system';

import { apolloClient } from 'apolloClient'

import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import store from './store'

import { theme } from './theme'

import './global.module.scss'

import 'assets/fonts/Roboto/stylesheet.css';
import 'assets/fonts/material-ui/material-icons.css';

import AppWithContext from './App'

import moment from 'moment-timezone'
import { ApolloProvider } from '@apollo/client';

moment.tz.setDefault('Pacific/Auckland')

setConfiguration({ 
  breakpoints: [576, 880, 992, 1200],
});

const ROUTER_BASENAME = process.env.CC_ROUTING_BASENAME || '/';

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ScreenClassProvider>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter basename={ROUTER_BASENAME}>
              <AppWithContext />
            </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </ScreenClassProvider>
  </ApolloProvider>
, document.getElementById('root'));

// need to trigger a resize event for react-grid-system to work by default
window.dispatchEvent(new Event('resize'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();