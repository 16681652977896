import React from 'react'

import styles from './index.module.scss'

export default class Page extends React.Component<any> {
  
  render() {
    return (
      <div className={styles.Page} {...this.props}>
        {this.props.children}
      </div>
    )
  }

}
