import moment from 'moment-timezone';

// [METER_NUMBER] : MULTIPLIER
export const METER_MULTIPLIERS = {
  1 : 1,
  2 : 10,
  3 : 10,
  4 : 10,
  5 : 1,
  6 : 1,
  7 : 1,
  8 : 1,
  9 : 10,
  10: 10,
  11: 1,
  20: 1,
  21: 1,
  22: 1,
  23: 1,
  24: 1,
};
export const METER_COLORS = {
  1 : '#A60842',
  2 : '#0AC4B6',
  3 : '#2698F7',
  4 : '#FF794A',
  5 : '#94CC33',
  6 : 'red',
  7 : 'green',
  8 : 'blue',
  9 : 'magenta',
  10: 'green',
  11: 'gold',
  20: '#673ab7',
  21: 'purple',
  22: 'crimson',
  23: 'black',
  24: 'darkslategray',
};


export function make10minIntervalArray(timeStart: Date, timeEnd: Date = null): Date[] {
  let arr : Date[] = [];
  const now = timeEnd ? moment(timeEnd).unix() : moment().unix();

  const roundedMinutes = Math.round(moment(timeStart).minute() / 30) * 30;
  const t0 = moment(timeStart).minute(roundedMinutes).seconds(0);

  for (let i = t0; i.unix() < now; i = i.add(30, 'minutes')) {
    arr.push(i.toDate());
  }
  return arr;
}
