import React from 'react'

export interface ErrorModalParams {
  show    : boolean
  title?  : string
  message?: string 
}

export class AppContextState {
  loading?    : boolean = false
  navbarTitle?: string  = 'FOREST SAFE'
  error?      : ErrorModalParams | null
}

export const initialState : AppContextState = {}

const AppContext = React.createContext<AppContextState>(initialState)

export const actions = (root:any) => {
  const getState = () => root.state
  const setState = (diff:any) => root.setState((s:any) => ({ ...s, ...diff }))

  const ctx : any = {
    async setLoading(loading: boolean) {
      setState({ loading })
      // approximately 200ms from now, the loading screen will have faded in
      // -- not fully precise calculation, but should do it
      return await new Promise(r => setTimeout(r, 200))
    },
    async setNavbarTitle(title: string) {
      setState({ navbarTitle: title })
    },
    showErrorModal(error: ErrorModalParams) {
      setState({ error : { ...error, show: true } })
    },
    hideErrorModal() {
      setState({ error: { ...getState().error, show: false } })
      setTimeout(() => setState({ error: null }), 1000);
    }
  };

  (window as any).ctx = ctx;
  return ctx;
}


export default AppContext;
/*
export const withContext = () : <P extends object> => (Component: ComponentType<P>) : ComponentClass<P> => {

  <P extends object>(WrappedComponent: ComponentType<P>) => ComponentClass<P> {
    return <P extends object>(WrappedComponent: ComponentType<P>) =>
      class BadgedComponent extends Component<P> {
      }
  }
  return (props: any)  => (
    <AppContext.Consumer>
    {
      context => <Component {...props} context = {context} />
    }
    </AppContext.Consumer>
  )
}


*/

export function withContext<Props, State>(WrappedComponent: React.ComponentClass<Props, State>) {

  return class ComponentWithContext extends React.Component<Props, State> {

    render() {
      return (
        <AppContext.Consumer>
        {
          context => <WrappedComponent {...this.props} context = {context} />
        }
        </AppContext.Consumer>
      )
    }

  }
}