import { gql } from '@apollo/client'

export const FRAG_DEVICE = gql`
  fragment frag_Device on Device { 
    _id
    deviceId
    deviceName
    battery
    gateways {
      _id
      gatewayId
      gatewayName
      rssi
      snr
    }
    lastWebhookReceivedAt
  }
`

export const GET_DEVICES = gql`
  query GetDevices {
    devices {
      ...frag_Device
    }
  }
  ${FRAG_DEVICE}
`
