import { MetersDrawer } from 'components/MetersDrawer';
import React, { CSSProperties, Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useGlobal } from 'reactn';
import AllMetersPage from './AllMeters';
import SingleMeterPage from './Meter';


export function MetersPage(props: any) {
  const [metersDrawerOpen, _] = useGlobal<any, 'metersDrawerOpen'>('metersDrawerOpen')
  const responsiveStyles : CSSProperties = {}
  if (metersDrawerOpen) {
    responsiveStyles.marginLeft = '6rem';
    responsiveStyles.padding= '0 12%';
  }
  return (
    <Fragment>
      <div style={{overflowX: 'hidden', ...responsiveStyles, height: '100%'}}>
        <MetersDrawer />
        <Switch>
          <Route path='/meter/all' exact component={AllMetersPage} />
          <Route path='/meter/:id' exact component={SingleMeterPage} />
          <Redirect to='/meter/all' />
        </Switch>
      </div>
    </Fragment>
  )
}
