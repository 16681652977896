import React from 'react'
import Center from 'layout/Center'

import styles from './index.module.scss'
import LoadingSpinner from 'components/LoadingSpinner';

const ANIMATION_DURATION = 200;

const Status = {
  HIDDEN      : 'hidden',
  FADING_IN   : 'fadingIn',
  VISIBLE     : 'visible',
  FADING_OUT  : 'fadingOut'
}

interface LoadingScreenProps {
  text: string
  visible: boolean
}

interface LoadingScreenState {
  status: string
}

export default class LoadingScreen extends React.Component<LoadingScreenProps, LoadingScreenState> {

  static defaultProps = {
    visible: false,
    text   : 'Loading'
  }

  constructor(props: LoadingScreenProps) {
    super(props);
    this.state = {
      status : props.visible ? Status.VISIBLE : Status.HIDDEN
    }
  }

  componentWillReceiveProps(nextProps: LoadingScreenProps) {
    if (!this.props.visible && nextProps.visible) {
      this.setState({ status: Status.FADING_IN })
      setTimeout(() => this.setState({ status: Status.VISIBLE }), ANIMATION_DURATION)
    } 
    if (this.props.visible && !nextProps.visible) {
      this.setState({ status: Status.FADING_OUT })
      setTimeout(() => this.setState({ status: Status.HIDDEN }), ANIMATION_DURATION)
    }
  }

  render() {
    if (this.state.status === Status.HIDDEN) return null

    let fx;

    switch (this.state.status) {
      //case Status.FADING_IN:    fx = styles[Status.FADING_IN]
        //break

      
      case Status.FADING_IN:      fx = styles[Status.VISIBLE]
        break


      case Status.FADING_OUT:   fx = styles[Status.FADING_OUT]
        break
      case Status.VISIBLE:      fx = styles[Status.VISIBLE]
        break
    }

    return (
      <div className={[
        styles.LoadingScreen,
        fx
      ].join(' ')}>
        <Center>
          <div style={{display: 'flex', alignItems: 'center', flexFlow: 'column'}}>
            <div className={styles.LogoSpinner}>
             {/* <img src={(process.env.CC_ROUTING_BASENAME ||'/')+'icon.png'} /> */}
              <LoadingSpinner size={250} color={'white'}/>
            </div>
            <h1>{this.props.text}</h1>
          </div>
        </Center>
      </div>
    )
  }

}
